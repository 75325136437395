/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useMutation, useQuery } from 'react-query'

import type {
  SaasAuthMfaActivateDeviceRequest,
  SaasAuthMfaDeviceResponse,
  SaasAuthMfaDevicesResponse,
  SaasAuthMfaEnabledRequest,
  SaasAuthMfaEnrollDeviceRequest,
} from '@modules/cloud-api/v1/types'
import {
  activateSaasCurrentUserMfaDeviceUrl,
  enrollSaasCurrentUserMfaDeviceUrl,
  getSaasCurrentUserMfaDevicesUrl,
  setSaasCurrentUserMfaEnabledUrl,
} from '@modules/cloud-api/v1/urls'
import { fetchAsJson } from '@modules/query/helpers'
import { queryClient } from '@modules/query'
import type { QueryHookOptions } from '@modules/query/types'
import type { ApiErrorCollection } from '@modules/ui-types/projects'
import type { DeviceType } from '@modules/mfa-management/types'

export const useGetSaasCurrentUserMfaDevicesQuery = (options?: QueryHookOptions) =>
  useQuery(
    ['getSaasCurrentUserMfaDevices'],
    () =>
      fetchAsJson<SaasAuthMfaDevicesResponse>(getSaasCurrentUserMfaDevicesUrl(), {
        method: 'get',
      }),
    options,
  )

/**
 * Query version of MFA device enrollment, for calls with no side-effects (just displaying the response payload)
 */
export const useEnrollSaasCurrentUserMfaDeviceQuery = (
  type: Extract<DeviceType, 'GOOGLE'>,
  options?: QueryHookOptions,
) => {
  const staleTime = 30 * 1000 // to align with validity window from API
  const cacheTime = 0 // to cleanup upon loss of last observer, and avoid a flash of old content
  const refetchOnMount = 'always' // to always get a new 30s validity window when displayed

  return useQuery(
    ['enrollSaasCurrentUserMfaDevice', type],
    () =>
      fetchAsJson<SaasAuthMfaDeviceResponse>(enrollSaasCurrentUserMfaDeviceUrl(), {
        method: 'post',
        body: JSON.stringify(<SaasAuthMfaEnrollDeviceRequest>{ device_type: type }),
      }),
    { staleTime, cacheTime, refetchOnMount, ...options },
  )
}

/**
 * Mutation version of MFA device enrollment, for calls with side-effects (e.g. sending a code via email)
 */
export const useEnrollSaasCurrentUserMfaDeviceMutation = () =>
  useMutation<
    SaasAuthMfaDeviceResponse,
    ApiErrorCollection,
    { type: Exclude<DeviceType, 'GOOGLE'> }
  >(({ type }) =>
    fetchAsJson<SaasAuthMfaDeviceResponse>(enrollSaasCurrentUserMfaDeviceUrl(), {
      method: 'post',
      body: JSON.stringify(<SaasAuthMfaEnrollDeviceRequest>{ device_type: type }),
    }),
  )

export const useActivateSaasCurrentUserMfaDeviceMutation = () =>
  useMutation<
    SaasAuthMfaDeviceResponse,
    ApiErrorCollection,
    { deviceId: string; passCode: string }
  >(
    ({ deviceId, passCode }) =>
      fetchAsJson<SaasAuthMfaDeviceResponse>(activateSaasCurrentUserMfaDeviceUrl({ deviceId }), {
        method: 'put',
        body: JSON.stringify(<SaasAuthMfaActivateDeviceRequest>{ pass_code: passCode }),
      }),
    { onSuccess: ({ status }) => onDeviceActivationSuccess(status) },
  )

const onDeviceActivationSuccess = (status: SaasAuthMfaDeviceResponse['status']) => {
  queryClient.invalidateQueries('getSaasCurrentUserMfaDevices')

  if (status === 'ACTIVE') {
    fetchAsJson<SaasAuthMfaDeviceResponse>(setSaasCurrentUserMfaEnabledUrl(), {
      method: 'put',
      body: JSON.stringify(<SaasAuthMfaEnabledRequest>{ enabled: true }),
    })
  }
}
