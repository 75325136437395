/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/**
 * Retrieves the current state and collection of feature flags from LaunchDarkly.
 *
 * @returns {Array} An array composed of:
 *   - isFlagUsable: A boolean indicating whether all feature flags have been successfully loaded.
 *   - flags: An object containing all the feature flags obtained from LaunchDarkly.
 *
 * @remarks
 * It's crucial to verify that LaunchDarkly is enabled, as certain environments (e.g., ECE) may not support it.
 */

import { useEffect, useState } from 'react'
// eslint-disable-next-line no-restricted-imports
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'

import { useConfig } from '@modules/cui/ConfigContext'
import Feature from '@modules/utils/feature'
import type { UserProfileFromApi } from '@modules/ui-types'
import { useGetUserProfileQuery } from '@modules/profile-lib/hooks'
import { getUserUsecase } from '@modules/profile-lib'

// eslint-disable-next-line import/no-restricted-paths
import { isElasticStaff } from '@/lib/validateEmail'

import type { LDContext } from 'launchdarkly-js-sdk-common'
import type { LDFlagSet } from 'launchdarkly-js-client-sdk'

export function useFlagsWhenLoaded(): [isFlagsUsable: boolean, ldFlags: LDFlagSet] {
  const ldEnabled = Boolean(useConfig(Feature.launchdarklyId))
  const [isFlagsUsable, setLoadedFlags] = useState(ldEnabled ? false : true)
  const ldClient = useLDClient()
  const flags = useFlags()

  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'
  const profile =
    useGetUserProfileQuery({ enabled: ldEnabled && !isAdminConsole }).data?.user ?? false

  useEffect(() => {
    if (ldClient === undefined || !ldEnabled || !profile) {
      return
    }

    ldClient.identify(makeLaunchDarklyContext(profile))
  }, [ldClient, ldEnabled, profile])

  if (ldEnabled && !isFlagsUsable) {
    // TODO convert this request with react query
    ldClient?.waitUntilReady().then(() => setLoadedFlags(true))
  }

  return [isFlagsUsable, ldEnabled ? flags : {}]
}

export function useServerlessFeatureFlag(): boolean {
  const isConfigFeatureFlagEnabled = useConfig(Feature.serverless)
  const isAdminconsole = useConfig('APP_NAME') === 'adminconsole'
  const [isFlagUsable, flags] = useFlagsWhenLoaded()

  // Launchdarkly is not available in adminconsole, we should rely only on the config feature flag in this case
  const isLaunchdarklyFeatureFlagEnabled =
    isAdminconsole || (isFlagUsable && flags.showServerlessFlag)

  // serverless is enabled only when both config and Launchdarkly feature flags are enabled at the same time
  return isConfigFeatureFlagEnabled && isLaunchdarklyFeatureFlagEnabled
}

export const makeLaunchDarklyContext = (profile: UserProfileFromApi): LDContext => ({
  key: profile.user_id.toString(),
  custom: {
    domain: profile.domain,
    inTrial: profile.is_trial,
    ...(profile.organization_id ? { organization: profile.organization_id } : {}),
    isElasticStaff: isElasticStaff(profile.email),
    useCase: getUserUsecase(profile),
  },
})
