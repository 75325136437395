/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton,
  EuiSpacer,
  EuiTitle,
  EuiIcon,
} from '@elastic/eui'

import SetupEmailDevice from '@modules/mfa-enforcement/EnableMfaPage/SetupEmailDevice'
import { useGetSaasCurrentUserMfaDevicesQuery } from '@modules/cloud-lib/users/hooks/mfa'

const EmailMfaAuthentication: React.FunctionComponent = () => {
  const [isModalOpen, openModal] = useState<boolean>(false)
  const { data } = useGetSaasCurrentUserMfaDevicesQuery()

  const isEmailMfaActive = data?.mfa_devices.some(
    (device) => device.device_type === 'EMAIL' && device.status === 'ACTIVE',
  )

  return (
    <Fragment>
      <EuiPanel paddingSize='l' hasBorder={true}>
        <EuiFlexGroup>
          <EuiFlexGroup direction='column' gutterSize='s'>
            <EuiFlexItem>
              <EuiFlexGroup justifyContent='spaceEvenly' alignItems='center' gutterSize='s'>
                <EuiFlexItem>
                  <EuiTitle size='s' className='user-settings-profile-card-title'>
                    <h3>
                      <FormattedMessage id='mfa-management.email.title' defaultMessage='Email' />
                    </h3>
                  </EuiTitle>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size='s'>
                <FormattedMessage
                  id='mfa-management.email.description'
                  defaultMessage='Receive a verification code on your email'
                />
              </EuiText>

              <EuiSpacer size='s' />
            </EuiFlexItem>

            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                {isEmailMfaActive ? (
                  <EuiButton size='s' color='danger'>
                    <FormattedMessage id='mfa-management.remove' defaultMessage='Remove' />
                  </EuiButton>
                ) : (
                  <EuiButton size='s' onClick={() => openModal(true)}>
                    <FormattedMessage id='mfa-management.set-up' defaultMessage='Set up' />
                  </EuiButton>
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexGroup>

          {isEmailMfaActive && (
            <EuiFlexItem grow={false}>
              <EuiIcon type='checkInCircleFilled' size='m' />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiPanel>

      {isModalOpen && (
        <SetupEmailDevice
          closeModal={() => {
            openModal(false)
          }}
        />
      )}
    </Fragment>
  )
}

export default EmailMfaAuthentication
