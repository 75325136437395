/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useEffect, useState } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

import type { EuiStepsProps } from '@elastic/eui'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiSteps,
  EuiText,
  useGeneratedHtmlId,
} from '@elastic/eui'

import {
  useActivateSaasCurrentUserMfaDeviceMutation,
  useEnrollSaasCurrentUserMfaDeviceMutation,
} from '@modules/cloud-lib/users/hooks/mfa'
import { CuiAlert } from '@modules/cui/Alert'
import useRevertingState from '@modules/utils/hooks/useRevertingState'

import genericMessages from '../genericMessages'

const messages = defineMessages({
  checkEmailTitle: {
    id: 'mfaEnforcement.email.checkEmail.title',
    defaultMessage: 'Check your email',
  },
  enterCodeTitle: {
    id: 'mfaEnforcement.email.enterCode.title',
    defaultMessage: 'Enter verification code',
  },
  enterCodeLabel: {
    id: 'mfaEnforcement.email.enterCode.label',
    defaultMessage: 'Enter the 6-digit code that you received.',
  },
  enterCodePlaceholder: {
    id: 'mfaEnforcement.email.enterCode.placeholder',
    defaultMessage: '6-digit code',
  },
})

const SetupEmailDevice: React.FunctionComponent<{
  closeModal: () => void
}> = ({ closeModal }) => {
  const { formatMessage } = useIntl()
  const modalTitleId = useGeneratedHtmlId()
  const [code, setCode] = useState('')
  const [canRequestNewCode, setCanRequestNewCode] = useRevertingState(true, 30 * 1000)

  const {
    data: device,
    mutate: enrollMutate,
    isLoading: enrollIsLoading,
  } = useEnrollSaasCurrentUserMfaDeviceMutation()
  const activateDeviceMutation = useActivateSaasCurrentUserMfaDeviceMutation()

  useEffect(() => {
    enrollMutate(
      { type: 'EMAIL' },
      {
        onSettled: () => {
          setCanRequestNewCode(false)
        },
      },
    )
  }, [enrollMutate, setCanRequestNewCode])

  const onRequestNewCode = () => {
    enrollMutate(
      { type: 'EMAIL' },
      {
        onSettled: () => {
          setCanRequestNewCode(false)
        },
      },
    )
  }

  const steps: EuiStepsProps['steps'] = [
    {
      title: formatMessage(messages.checkEmailTitle),
      children: (
        <Fragment>
          <EuiText size='s'>
            <FormattedMessage
              id='mfaEnforcement.email.enterCode.instructions'
              defaultMessage="<p>We sent a verification code to {email}.</p><p>If you haven't received it within a few minutes, check your spam folder.</p>"
              values={{
                p: (content) => <p>{content}</p>,
                email: <strong>{device?.email_address}</strong>,
              }}
            />
          </EuiText>
          <EuiSpacer />
          <div>
            {canRequestNewCode ? (
              <EuiButton isLoading={enrollIsLoading} onClick={onRequestNewCode}>
                <FormattedMessage
                  id='mfaEnforcement.email.enterCode.resendButton'
                  defaultMessage='Request new code'
                />
              </EuiButton>
            ) : (
              <EuiButton disabled={true} iconType='checkInCircleFilled'>
                <FormattedMessage
                  id='mfaEnforcement.email.enterCode.sentCode'
                  defaultMessage='Code sent'
                />
              </EuiButton>
            )}
          </div>
        </Fragment>
      ),
    },
    {
      title: formatMessage(messages.enterCodeTitle),
      children: (
        <Fragment>
          <EuiText size='s'>
            <FormattedMessage {...messages.enterCodeLabel} />
          </EuiText>
          <EuiSpacer size='xs' />
          <EuiFieldText
            placeholder={formatMessage(messages.enterCodePlaceholder)}
            autoComplete='off'
            aria-label={formatMessage(messages.enterCodeLabel)}
            style={{ maxWidth: '20rem' }}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          {activateDeviceMutation.isError && (
            <Fragment>
              <EuiSpacer />
              <CuiAlert type='danger'>{activateDeviceMutation.error.errors[0]?.message}</CuiAlert>
            </Fragment>
          )}
        </Fragment>
      ),
    },
  ]

  const onSubmit = () => {
    if (!device) {
      return // sanity
    }

    activateDeviceMutation.mutate(
      { deviceId: device.device_id, passCode: code },
      {
        onSuccess: () => {
          closeModal()
        },
      },
    )
  }

  return (
    <EuiModal maxWidth='44rem' aria-labelledby={modalTitleId} onClose={closeModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle id={modalTitleId}>
          <FormattedMessage
            id='mfaEnforcement.email.title'
            defaultMessage='Set up email authentication method'
          />
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiSteps steps={steps} />
      </EuiModalBody>

      <EuiModalFooter>
        <EuiFlexGroup justifyContent='flexEnd'>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty onClick={closeModal}>
              <FormattedMessage {...genericMessages.cancel} />
            </EuiButtonEmpty>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton fill={true} isLoading={activateDeviceMutation.isLoading} onClick={onSubmit}>
              <FormattedMessage {...genericMessages.enableButton} />
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  )
}

export default SetupEmailDevice
